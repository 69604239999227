<div class="card">
  <div class="card-body">
    <h5>Prescriber</h5>

    <app-prescriber-incorrect-information-prompt
      [client]="task.client"
      relatedRecordType="ClientPrescriberTask"
      [relatedRecordId]="task.id"
      [provider]="task.provider"
      [prescriberId]="task.prescriberId" />

    <div class="row mb-2">
      <div class="col">
        <div><strong>PRESCRIBER</strong></div>
        <div><app-prescriber-name [provider]="task.provider" /></div>
      </div>
      <div class="col">
        <div><strong>SPECIALTY</strong></div>
        <div>{{ task.provider.displaySpecialty }}</div>
      </div>
    </div>
  </div>
</div>
<div class="card mt-2">
  <div class="card-body">
    <h5>Rx Details</h5>
    <div class="row mb-2">
      <div class="col">
        <div><strong>DRUG</strong> <span class="small">(including AKAs)</span></div>
        <div><app-prescription-name [prescription]="task.prescription" /></div>
      </div>
      <div class="col">
        <div><strong>WRITTEN DATE</strong></div>
        <div>{{ task.prescription.writtenDate | date: 'MM/dd/yyyy' }}</div>
      </div>
    </div>
  </div>
</div>
<app-client-task-encounters [patient]="task.patient" />
<div class="card mt-2">
  <div class="card-body">
    <app-client-prescriber-task-related-tasks [task]="task" />
  </div>
</div>
