<form novalidate (keydown.enter)="onFormEnter($event)">
  <div class="row g-0 flex-row align-items-end">
    <div class="col-4">
      <div class="my-0 py-0 me-2">
        <label for="userFirstName" class="my-0"><strong>First Name</strong></label>
        <input
          id="userFirstName"
          class="form-control"
          name="userFirstName"
          placeholder="Filter By First Name"
          (change)="onTextFilterChange()"
          [(ngModel)]="formModel.userFirstName"
        >
      </div>
    </div>
    <div class="col-4">
      <div class="my-0 py-0 me-2">
        <label for="userLastName" class="my-0"><strong>Last Name</strong></label>
        <input
          id="userLastName"
          class="form-control"
          name="userLastName"
          placeholder="Filter By Last Name"
          (change)="onTextFilterChange()"
          [(ngModel)]="formModel.userLastName"
        >
      </div>
    </div>
    <div class="col-4">
      <div class="my-0 py-0 me-2">
        <label for="userEmail" class="my-0"><strong>Email</strong></label>
        <input
          id="userEmail"
          class="form-control"
          name="userEmail"
          placeholder="Filter By Email"
          (change)="onTextFilterChange()"
          [(ngModel)]="formModel.userEmail"
        >
      </div>
    </div>
    <div class="col-4">
      <div class="my-0 py-0 me-2">
        <label for="userClients" class="my-0"><strong>Clients</strong></label>
        <ng-select
          class="w-100"
          bindValue="id"
          bindLabel="humanizedName"
          name="userClients"
          placeholder="Filter by Client"
          [multiple]="true"
          [items]="availableClients"
          (remove)="onSelectFilterChange()"
          (change)="onSelectFilterChange()"
          [(ngModel)]="formModel.userClients"
        />
      </div>
    </div>
    <div class="col-4">
      <div class="my-0 py-0 me-2">
        <label for="userTypes" class="my-0"><strong>User Type</strong></label>
        <ng-select
          id="userTypes"
          class="w-100"
          bindValue="value"
          bindLabel="display"
          name="userTypes"
          placeholder="Filter by User Type"
          [multiple]="true"
          [items]="userTypes"
          (remove)="onSelectFilterChange()"
          (change)="onSelectFilterChange()"
          [(ngModel)]="formModel.userTypes"
        />
      </div>
    </div>
    <div class="col-4">
      <div class="my-0 py-0 me-2">
        <label for="userStatuses" class="my-0"><strong>Status</strong></label>
        <ng-select
          id="userStatuses"
          class="w-100"
          bindValue="value"
          bindLabel="display"
          name="userStatuses"
          placeholder="Filter by Status"
          [multiple]="true"
          [items]="userStatuses"
          (remove)="onSelectFilterChange()"
          (change)="onSelectFilterChange()"
          [(ngModel)]="formModel.userStatuses"
        />
      </div>
    </div>
    <div class="col-4">
      <div class="my-0 py-0 me-2">
        <label for="userTypes" class="my-0"><strong>User Roles</strong></label>
        <ng-select
          id="userRoles"
          class="w-100"
          bindValue="name"
          bindLabel="humanizedName"
          name="userRoles"
          placeholder="Filter by User Roles"
          [multiple]="true"
          [items]="userRoles"
          (remove)="onSelectFilterChange()"
          (change)="onSelectFilterChange()"
          [(ngModel)]="formModel.userRoles"
        />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-2">
      <button class="btn btn-info mt-2 clear-button" (click)="onClearAllClick($event)">Clear All</button>
    </div>
  </div>
</form>
