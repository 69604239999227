import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { UserStatus } from '../../../core/enums/user-status.enum';
import { UserType } from '../../../core/enums/user-type.enum';
import { UserListFilters } from '../../../core/models/user-settings/user-management-settings.model';
import { ClientsService } from '../../../core/services/clients.service';
import { displayUserStatus } from 'app/core/options/user-status.opts';
import { displayUserType } from 'app/core/options/user-type.opts';
import { searchableRoles } from 'app/core/enums/user-role-name.enum';

@Component({
  selector: 'app-user-filters',
  templateUrl: './user-filters.component.html',
  styleUrls: ['./user-filters.component.scss'],
})
export class UserFiltersComponent implements OnInit, OnChanges {
  @Input() filters: UserListFilters;
  @Output() public filtersChange = new EventEmitter<UserListFilters>();

  formModel = {
    userFirstName: null,
    userLastName: null,
    userEmail: null,
    userClients: null,
    userTypes: null,
    userStatuses: null,
    userRoles: null,
  };
  userTypes = [];
  userStatuses = [];
  availableClients = [];
  userRoles = [];

  userFirstName;
  userLastName;
  userEmail;

  constructor(
    private clientsService: ClientsService
  ) {}

  ngOnInit() {
    this.init();
  }

  ngOnChanges() {
    this.init();
  }

  onFormEnter($event) {
    $event.preventDefault();
    this.emitFilterChange();
  }

  onTextFilterChange() {
    if (this.filtersChanged()) {
      this.emitFilterChange();
      this.recordCurrentFilterState();
    }
  }

  onSelectFilterChange() {
    this.emitFilterChange();
  }

  emitFilterChange() {
    this.filtersChange.emit(this.createUserListFilters());
  }

  onClearAllClick($event) {
    $event.preventDefault();
    this.formModel = {
      userFirstName: null,
      userLastName: null,
      userEmail: null,
      userClients: null,
      userTypes: null,
      userStatuses: null,
      userRoles: null,
    };
    this.emitFilterChange();
  }

  private init() {
    this.loadUserTypes();
    this.loadUserStatuses();
    this.loadUserRoles();
    this.loadClients();

    this.formModel = {
      userFirstName: this.filters.userFirstName,
      userLastName: this.filters.userLastName,
      userEmail: this.filters.userEmail,
      userClients: this.filters.userClients,
      userTypes: this.filters.userTypes,
      userStatuses: this.filters.userStatuses,
      userRoles: this.filters.userRoles,
    };
    this.recordCurrentFilterState();
  }

  private recordCurrentFilterState() {
    this.userFirstName = this.formModel.userFirstName;
    this.userLastName = this.formModel.userLastName;
    this.userEmail = this.formModel.userEmail;
  }

  private loadUserTypes() {
    this.userTypes = Object.values(UserType)
      .sort((a, b) => a.localeCompare(b, 'en', { sensitivity: 'base' }))
      .map(v => ({
        value: v,
        display: displayUserType(v),
      }));
  }

  private loadUserStatuses() {
    this.userStatuses = Object.values(UserStatus)
      .sort((a, b) => a.localeCompare(b, 'en', { sensitivity: 'base' }))
      .map(v => ({
        value: v,
        display: displayUserStatus(v),
      }));
  }

  private loadUserRoles() {
    this.userRoles = searchableRoles;
  }

  private createUserListFilters(): UserListFilters {
    return new UserListFilters(
      this.formModel.userFirstName,
      this.formModel.userLastName,
      this.formModel.userEmail,
      this.formModel.userClients,
      this.formModel.userTypes,
      this.formModel.userStatuses,
      this.formModel.userRoles
    );
  }

  private filtersChanged() {
    return (
      this.formModel.userFirstName !== this.userFirstName ||
      this.formModel.userLastName !== this.userLastName ||
      this.formModel.userEmail !== this.userEmail
    );
  }

  private loadClients() {
    this.clientsService.getList(null, { applicableToReferralClaims: true }).subscribe(result => {
      this.availableClients = result.clients;
    });
  }
}
