import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PatientService } from 'app/core/services/patient.service';
import { PatientAttachmentsService } from 'app/core/services/patient-attachments.service';
import { ProviderService } from '../../../core/services/provider.service';
import { OfficeService } from 'app/core/services/office.service';
import { Patient } from 'app/core/models/patient.model';
import { PatientAttachment } from 'app/core/models/patient-attachment.model';
import { PatientDocument } from 'app/core/models/patient-document.model';
import { ProviderOffice } from '../../../core/models/provider-office.model';
import { Office } from 'app/core/models/office.model';
import { switchMap, finalize } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { PdfFileType } from '../../../core/enums/pdf-file-type.enum';
import { keyedStatusOptions as keyedOfficeStatusOptions } from 'app/core/options/office-status.opts';
import { keyedStatusOptions as keyedproviderOfficeStatusOptions } from '../../../core/options/provider-office-status.opts';

@Component({
  selector: 'app-capture-admin-patient-documents',
  templateUrl: './capture-admin-patient-documents.component.html',
  styleUrls: ['./capture-admin-patient-documents.component.scss'],
})
export class CaptureAdminPatientDocumentsComponent implements OnInit {
  patient: Patient;
  patientDocuments: PatientDocument[];
  loading: boolean;
  loadingDocument: boolean;
  selectedDocument: PatientDocument;
  currentDocument: PatientAttachment;
  outreachAttemptEvidenceOffices: Office[] = [];
  usePdfKitViewer: boolean;
  pdfFileType: PdfFileType = PdfFileType.patientAttachment;
  providerOffices: ProviderOffice[];
  providerOfficeFaxStatuses: { [key: number]: string } = {};
  tables = [];
  keyedOfficeStatusOptions = keyedOfficeStatusOptions;
  keyedproviderOfficeStatusOptions = keyedproviderOfficeStatusOptions;

  constructor(
    private patientService: PatientService,
    private patientAttachmentService: PatientAttachmentsService,
    private officeService: OfficeService,
    private providerService: ProviderService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.usePdfKitViewer = environment.featureData?.usePdfKitViewer
    this.init();
  }

  displayDocument(id: number): void {
    this.selectedDocument = this.patientDocuments.find(document => document.id === id);
    this.providerOfficeFaxStatuses = {};
    this.loadingDocument = true;
    this.loadDocument(id, this.selectedDocument.type);
  }

  private buildTables() {
    this.tables = [
      { title: 'Consult Notes', documents: this.documentsByType('StandardConsultNote') },
      { title: 'EHR References', documents: this.documentsByType('EhrConsultNoteReference') },
      { title: 'Outreach Attempt Evidence', documents: this.documentsByType('OutreachAttemptEvidence') }
    ]
  }

  private documentsByType = (type: string) => {
    let documents = this.patientDocuments?.filter(document => document.type === type) || []

    if (type === 'StandardConsultNote') {
      documents = this.partitionStandardConsultNotes(documents)
    }

    return documents;
  }

  private partitionStandardConsultNotes(documents: PatientDocument[]): PatientDocument[] {
    const sortedDocuments = documents.reduce((acc, doc) => {
      if (doc.displayName.startsWith('VALID')) {
        acc.valid.push(doc);
      } else if (doc.displayName.startsWith('INVALID')) {
        acc.invalid.push(doc);
      }
      return acc;
    }, { valid: [], invalid: [] });
    return [...sortedDocuments.valid, ...sortedDocuments.invalid];
  }

  private init() {
    const patientId = parseInt(this.route.snapshot.paramMap.get('id'), 10);
    this.loading = true;

    this.patientService.get(patientId).pipe(
      switchMap(patient => {
        this.patient = patient;
        return this.patientService.getDocuments(patient.id)
      }),
      finalize(() => this.loading = false)
    ).subscribe((result: PatientDocument[]) => {
      this.patientDocuments = result
      this.buildTables();
    },
      error => {
        console.error('Error fetching data', error);
        this.loading = false;
      }
    );
  }

  onUrlChange(url: string) {
    this.currentDocument.url = url;
  }

  private loadDocument(id: number, type: string) {
    this.patientAttachmentService.get(id).subscribe((patient_attachment: PatientAttachment) => {
      this.currentDocument = patient_attachment;

      if (type === 'OutreachAttemptEvidence' && patient_attachment.provider) {
        this.loadOutreachAttemptSupplementaryData(patient_attachment);
      } else {
        this.loadingDocument = false;
      }
    })
  }

  private loadOutreachAttemptSupplementaryData(patient_attachment: PatientAttachment) {
    const officeIds = patient_attachment.outreachAttemptEvidence.officeIds;
    this.loadOutreachAttempEvidenceOffices(officeIds).subscribe((results) => {
      this.outreachAttemptEvidenceOffices = results
      this.loadProviderOffices(patient_attachment.provider.id)
    }, (error) => {
      this.loadingDocument = false;
      console.error('Error loading supplementary data:', error);
    });
  }

  private loadOutreachAttempEvidenceOffices(officeIds: number[]): Observable<any[]> {
    const officeObservables: Observable<any>[] = officeIds.map((id) =>
      this.officeService.get(id.toString())
    );
    return forkJoin(officeObservables);
  }

  private loadProviderOffices(providerId: number) {
    this.providerService.getProviderOffices(providerId).subscribe(
      (results: ProviderOffice[]) => {
        this.buildProviderOfficeFaxStatuses(results)
      }, (error) => {
        console.error('Error loading provider offices:', error);
      });
    this.loadingDocument = false;
  }

  private buildProviderOfficeFaxStatuses(providerOffices: ProviderOffice[]): void {
    this.outreachAttemptEvidenceOffices.forEach((outreachOffice) => {
      const providerOffice = providerOffices?.find(po => po.office.id === outreachOffice.id);
      this.providerOfficeFaxStatuses[outreachOffice.id] =
        keyedproviderOfficeStatusOptions[providerOffice?.status]?.display ||
        providerOffice?.status ||
        "-"
    })
  }
}
