<ng-container *ngIf="!loading; else: spinner">
  <app-patient-header [patient]="patient" />

  <div class="row">
    <div class="col-6">
      <div *ngFor="let table of tables">
        <div class="card mt-3">
          <div class="card-body">
            <h4>{{ table.title }}</h4>
            <ng-container *ngIf="table.documents.length > 0; else noDocuments">
              <table class="table table-sm table-bordered table-hover">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>DOCUMENT NAME</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let document of table.documents"
                    [ngClass]="document.id === selectedDocument?.id ? 'selected' : 'clickable'"
                    (click)="displayDocument(document.id)" >
                    <td>{{ document.id }}</td>
                    <td>{{ document.displayName }}</td>
                  </tr>
                </tbody>
              </table>
            </ng-container>
            <ng-template #noDocuments>No documents.</ng-template>
          </div>
        </div>
      </div>
    </div>

    <div class="col-6">
      <div class="card mt-3" *ngIf="selectedDocument">
        <div class="card-title p-2">
          VIEWING: <a routerLink="/capture-admin/consult-notes/{{selectedDocument.id}}">{{ selectedDocument.displayName }}</a>
        </div>
        <div class="card-body" *ngIf="!loadingDocument; else: documentSpinner">
          <div class="card mt-3 p-2">
            <div [ngSwitch]="selectedDocument.type">
              <div *ngSwitchCase="'StandardConsultNote'">
                <ng-container *ngTemplateOutlet="standardConsultNoteTemplate" />
              </div>
              <div *ngSwitchCase="'EhrConsultNoteReference'">
                <ng-container *ngTemplateOutlet="ehrConsultNoteReferenceTemplate" />
              </div>
              <div *ngSwitchCase="'OutreachAttemptEvidence'">
                <ng-container *ngTemplateOutlet="outreachAttemptEvidenceTemplate" />
              </div>
            </div>
          </div>

          <ng-template #ehrConsultNoteReferenceTemplate>
            <div class="card-title p-2">
              Consult Note Reference
            </div>
            <div class="card-body">
              <div><strong>Consult Note Name</strong></div>
              <div>{{ currentDocument.ehrConsultNoteReference.name }}</div>
              <div class="mt-3"><strong>Client Note</strong></div>
              <div>{{ currentDocument.ehrConsultNoteReference.notes }}</div>
              <div class="mt-3"><strong>Created</strong></div>
              <div>{{ currentDocument.ehrConsultNoteReference.createdAt | date: 'MM/dd/yyyy  hh:mm a' }} - {{ currentDocument.ehrConsultNoteReference.user.email }}</div>
            </div>
          </ng-template>

          <ng-template #outreachAttemptEvidenceTemplate>
            <div class="card-body">
              <h4>Outreach Attempt Evidence</h4>
              <div class="mb-3" *ngIf="currentDocument.provider?.fullName; else noProvider">
                Office(s) for {{ currentDocument.provider?.fullName.toUpperCase() }} with relevant outreach attempts.
              </div>
              <ng-template #noProvider>
                <div class="fst-italic mb-3">No provider is associated with this outreach attempt evidence record.</div>
              </ng-template>
              <table *ngIf="outreachAttemptEvidenceOffices.length > 0; else noOffices" class="table">
                <thead>
                  <tr>
                    <th>OFFICE DETAILS</th>
                    <th>FAX STATUS</th>
                    <th>PROVIDER FAX STATUS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let office of outreachAttemptEvidenceOffices">
                    <td class="small">
                      <app-office [office]="office" /> </td>
                    <td>{{ keyedOfficeStatusOptions[office.status].display }}</td>
                    <td>{{ providerOfficeFaxStatuses[office.id] }}</td>
                  </tr>
                </tbody>
              </table>
              <ng-template #noOffices>
                <div class="fst-italic mt-2">No offices are associated with this outreach attempt evidence record.</div>
              </ng-template>
              <div class="mt-3"><strong>Effective Date</strong></div>
              <div>{{ currentDocument.outreachAttemptEvidence.lastOutreachDate }}</div>
              <div class="mt-3"><strong>Justification</strong></div>
              <div>{{ currentDocument.outreachAttemptEvidence.notes }}</div>
            </div>
          </ng-template>

          <ng-template #standardConsultNoteTemplate>
            <ng-container *ngIf="currentDocument.url; else: noPdfUrl">
              <app-pdf-file-viewer
                *ngIf="!usePdfKitViewer"
                height="86rem"
                [pdfFile]="currentDocument"
                [pdfFileType]="pdfFileType" />
              <app-pdfkit-file-viewer *ngIf="usePdfKitViewer"
                height="86rem"
                [pdfFile]="currentDocument"
                [pdfFileType]="pdfFileType"
                (urlChange)="onUrlChange($event)" />
            </ng-container>
            <ng-template #noPdfUrl> No PDF file is associated with the attachment. </ng-template>
          </ng-template>

        </div>
        <ng-template #documentSpinner> <mat-spinner class="mt-2 mb-2 centered" [diameter]="30"/> </ng-template>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #spinner> <mat-spinner /> </ng-template>
